


































import { Component, Vue } from 'vue-property-decorator'
import menuStore from '@/store/Menu'
import usersStore from '@/store/Users'
import config from '@/config'

@Component
export default class extends Vue {
  email = ''
  password = ''
  authErrorFlag = false

  get signUpUrl(): string {
    return `${config.main.clientUrl}/#/agreement/document`
  }

  mounted(): void {
    menuStore.setInvisible()
  }

  submit(): void {
    usersStore
      .authenticateUser({ email: this.email, password: this.password })
      .then(() => this.$router.push({ name: 'AccountRegistration' }))
      .catch((err) => {
        console.log('認証に失敗しました', err)
        this.authErrorFlag = true
      })
  }
}
